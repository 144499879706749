import React from "react";
import { alert } from "../../Utils/Alert";
import { instance } from "../../Utils/Api";
import {
  formatCvn,
  formatExpirationDate,
  formatNumber,
  validateCardCvn,
  validateCardExpiry,
  validateCardNumber,
} from "../../Utils/CardLogic";

import { getCardType } from "../../Utils/CardType";
import { CreditCardError } from "../../Utils/CreditCardError";
import { analytics, eventMask, gaLogEvent } from "../../Utils/FirebaseConfig";

const CreditCardXendit = ({
  amount,
  setData,
  setShowFrame,
  setSrcFrame,
  external_id,
  payment_gateway_id,
  url,
  navigate,
  setIsLoading,
}) => {
  const [CreditCard, setCreditCard] = React.useState({
    number: "",
    cvc: "",
    expiry: "",
  });

  const [CreditCardValid, setCreditCardValid] = React.useState({
    number_valid: "",
    cvc_valid: "",
    expiry_valid: "",
  });

  const [CreditCardType, setCreditCardType] = React.useState("");

  const { number, expiry, cvc } = CreditCard;
  const { number_valid, expiry_valid, cvc_valid } = CreditCardValid;

  const xenditResponseHandler = (err, creditCardToken) => {
    try {
      if (err) {
        alert("warn", err.message);
        setIsLoading(false);
        // setDisableBtnCreateToken(false);
      }
      if (["VERIFIED", "APPROVED"].includes(creditCardToken?.status)) {
        // Get the token ID:
        const token = creditCardToken.id;
        const payload = {
          token_id: token,
          external_id,
          card_cvn: cvc,
          payment_gateway_id,
        };

        instance.post(url, payload).then((res) => {
          setShowFrame(false);
          if (res.data.data.status === "CAPTURED") {
            // send event to google analytic
            gaLogEvent(
              analytics,
              eventMask(process.env.REACT_APP_EVENT_CHECKOUT_CLICK)
            );

            window.location.href = res.data.data?.redirect_url;

          } else {
            alert(
              "warn",
              CreditCardError[res.data.data.status] ||
                "Please contact your card issuing bank to resolve the problem on online transaction"
            );

            setTimeout(() => {
              navigate(`/?q=${external_id}`);
            }, 4000);
          }
        });
      } else if (creditCardToken?.status === "IN_REVIEW") {
        setSrcFrame(creditCardToken.payer_authentication_url);
        setShowFrame(true);
      } else if (creditCardToken?.status === "FAILED") {
        setShowFrame(false);
        alert("error", creditCardToken.failure_reason);
        // Re-enable submission
        // setDisableBtnCreateToken(false);
      }
    } catch (error) {
      console.log(error, "<<<");
    }
  };

  const handleChange = ({ target }) => {
    let { name, value } = target,
      valid = "";
    if (name === "number") {
      value = formatNumber(value);
      if (value.replace(/\s/g, "").length === 16) {
        valid = validateCardNumber(value.replace(/\s/g, ""));
      }

      setCreditCardType(getCardType(value));
    }

    if (name === "expiry") {
      value = formatExpirationDate(value);
      valid = validateCardExpiry(...value.split("/"));
    }

    if (name === "cvc") {
      value = formatCvn(value);
      valid = validateCardCvn(value);
    }

    setCreditCard({ ...CreditCard, [name]: value });

    setCreditCardValid({
      ...CreditCardValid,
      [`${name}_valid`]: valid ? "is-valid" : "is-invalid",
    });
  };

  const numberValid =
    number_valid === "is-valid" && CreditCardType !== ""
      ? CreditCardType
      : number_valid;

  const formXenditCreditCard = [
    {
      label: "Nomor Kartu",
      div: {
        type: "tel",
        id: "number",
        name: "number",
        onChange: handleChange,
        value: number,
        className: `form-control font-averta font-size-14 mb-2 ${numberValid}`,
        placeholder: "0000 0000 0000 0000",
      },
      className: "col-12",
    },
    {
      label: "Masa Berlaku",
      div: {
        type: "tel",
        id: "expiry",
        name: "expiry",
        onChange: handleChange,
        value: expiry,
        className: `form-control font-averta font-size-14 ${expiry_valid}`,
        placeholder: "MM / YYYY",
      },
      className: "col-8",
    },
    {
      label: "CVV",
      div: {
        type: "tel",
        id: "cvc",
        name: "cvc",
        onChange: handleChange,
        value: cvc,
        className: `form-control font-averta font-size-14 ${cvc_valid}`,
        placeholder: "123",
      },
      className: "col-4",
    },
  ];

  React.useEffect(() => {
    setData({
      callback: xenditResponseHandler,
      payload: {
        card_number: number.replace(/\s/g, ""),
        card_exp_month: expiry.split("/")[0],
        card_exp_year: expiry.split("/")[1],
        card_cvn: cvc,
        amount,
        is_multiple_use: false,
        should_authenticate: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CreditCard]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <span className="ps-3 font-averta-bold font-size-16">Kartu Kredit</span>
        <img
          src={`${window.location.origin}/cc.png`}
          alt=""
          className="ps-3"
          style={{ width: 150 }}
        />
      </div>
      <div className="gap"></div>
      {/* {ShowFrame && <Frame src={SrcFrame} />} */}
      <form className="row px-3">
        {formXenditCreditCard.map((e, i) => (
          <div key={i} className={e.className}>
            <label
              htmlFor={e.div.name}
              className="form-label font-averta font-size-14 ps-0"
            >
              {e.label}
            </label>
            <input {...e.div} />
          </div>
        ))}
      </form>
    </div>
  );
};

export default CreditCardXendit;
